<app-skeleton-table-multilocations *ngIf="isLoading" [isInfoTable]="true"></app-skeleton-table-multilocations>

<div class="container--full p--0 pt--0" *ngIf="!isLoading">
  <div class="box box--shadow box--rounded bg--white">
    <div class="box__header d-flex justify-content-between align-items-center alternate-theme">
      <h2 class="txt--capitalize"><strong><span class="title-dark-launch">{{title}}</span></strong></h2>
      <div>
        <button mat-flat-button color="primary" class="btn btn--xs" *ngIf="hasExportPDF" (click)="exportPDF()" style="margin-right: 5px;" gmbSubscriptionInTrial>Export PDF</button>
        <button mat-flat-button color="primary" class="btn btn--xs" *ngIf="hasExportCSV" (click)="exportCSV()" gmbSubscriptionInTrial>
          Export CSV <em *ngIf="isProgressCSV" class="fas fa-spinner fa-pulse"></em>
        </button>
      </div>
    </div>
  </div>

  <div class="box__content" *ngIf="dataSource?.data?.length; else emptyTable">
    <div class="table-wrapper js-table-scroll table-multi-locations">
      <table 
      mat-table 
      id="table-location-container"  
      *ngIf="!isLoading && dataSource" 
      [dataSource]="dataSource" 
      class="table table--no--bordered" 
      matSort 
      matSortDisableClear
      matSortStart="desc"
      [matSortActive]="sort && sort.sortBy" 
      [matSortDirection]="sort?.direction || ''"
      (matSortChange)="handleSort($event)" 
      summary="multilocation table">
        <ng-container *ngFor="let col of displayedColumns ; let i = index">
          <ng-container *ngIf="col == 'location'" [matColumnDef]="col">
            <th mat-header-cell *matHeaderCellDef [mat-sort-header]="fieldsColumns[i]?.fieldSort" class="txt--black">{{ buildName(col) | titlecase }}</th>
            <td mat-cell *matCellDef="let row">
              <div class="cell__content flex-column align-items-start">
                <p class="m--0 txt--black txt--md"><strong>{{ row[fieldsColumns[i]?.displayName] }}</strong><br></p>
                <p class="txt--lightgray" *ngIf="col">
                  <gmb-address [address]='row?.address' [serviceAreas]="row?.serviceArea?.places?.placeInfos"></gmb-address>
                </p>
                <div class="mt--5" *ngIf="!isShared">
                  <a [routerLink]="locationUrl(row.accountId,row.locationId,'insights')" target="_blank"
                    matTooltip="View Insights" matTooltipClass="tooltip tooltip--blue" matTooltipPosition="above"
                    class="d-inline-block btn btn--blue btn-icon mr--5"><img src="/assets/images/icons/graph.png" alt="Graph Icon">
                  </a>
                  <a [routerLink]="locationUrl(row.accountId,row.locationId,'reviews')" target="_blank"
                    matTooltip="See Reviews" matTooltipClass="tooltip tooltip--orange" matTooltipPosition="above"
                    class="d-inline-block btn btn--orange btn-icon mr--5"><img src="/assets/images/icons/star-empty.png" alt="Star Icon">
                  </a>
                  
                  <a [href]="row?.mapsUrl" target="_blank" matTooltip="See Location on Map"
                    matTooltipClass="tooltip tooltip--green" matTooltipPosition="above"
                    class="d-inline-block btn btn--green btn-icon"><img src="/assets/images/icons/pin-green.png" alt="Pin Icon">
                  </a>
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container [matColumnDef]="col" *ngIf="col != 'location' && col != 'goLocation'">
            <section *ngIf="fieldsColumns[i]?.fieldSort">
              <th mat-header-cell *matHeaderCellDef [mat-sort-header]="fieldsColumns[i]?.fieldSort" class="txt--center txt--black">
                {{ buildName(col) | titlecase }}
              </th>
            </section>
            <section *ngIf="!fieldsColumns[i]?.fieldSort">
              <th mat-header-cell *matHeaderCellDef class="txt--center txt--black" matSortDisabled>
                {{ buildName(col) | titlecase }}
              </th>
            </section>
            <td mat-cell *matCellDef="let row">
              <div class="flex-column align-items-start justify-content-center">
                <p *ngIf="typeElement(row[fieldsColumns[i]?.displayName]) == 'number'" class="m--0 txt--black txt--md txt--center">{{ row[fieldsColumns[i]?.displayName] | number }}</p>
                <p *ngIf="typeElement(row[fieldsColumns[i]?.displayName]) == 'string'" class="m--0 txt--black txt--md txt--center">{{ row[fieldsColumns[i]?.displayName] }}</p>
              </div>
            </td>
          </ng-container>

          
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="no-hover"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="no-hover border--full"></tr>
      </table>
    </div>

    <app-paginator *ngIf="pagination" [pagination]="pagination" (reload)="handleReload($event)" [options]="[10, 25,50,100,200,500]"></app-paginator>
  </div>

  <ng-template #emptyTable>
    <div class="box box--rounded box--shadow bg--white mb--30">
      <div class="box__header d-flex align-items-center justify-content-center">
        <h2 class="txt--capitalize"><strong>No multi location data available</strong></h2>
      </div>
    </div>
  </ng-template>
</div>
